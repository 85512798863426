import { useEffect, useState } from "react"
import logo from "../../assets/brand/logo.svg";

const Loader = () => {

  const quotes = [
    "Meer wird gesalzen",
    "Flugrouten werden berechnet",
    "Sandflöhe werden gezähmt",
    "Fische werden geschwärmt",
    "Wolken werden verjagt",
    "Sonne wird aufgeladen",
    "Sandkörner werden gezählt"
  ];

  const [quote, setQuote] = useState("Inhalt wird geladen");

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    const quotesMixer = setInterval(() => setQuote(prev => {
      const filtered = quotes.filter(q => q !== prev);
      const newQuote = filtered[Math.floor(Math.random() * filtered.length)];
      return(newQuote);
    }), 1800);
    return () => {
    clearInterval(quotesMixer);
    document.body.classList.remove('overflow-hidden');
    }
  // eslint-disable-next-line
  }, []);

  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-gradient-to-br from-congress-600 to-congress-900 z-[10000] text-congress-100 flex items-center justify-center">
      <div className="mx-4 w-full md:max-w-screen-md">
        <img src={logo} className="w-full h-auto mb-12" alt="UnterwegsErleben.de" />
        <p className="font-protest text-center text-lg md:text-2xl lg:text-4xl my-6">{ quote }...</p>
        <div className="h-10 w-10 mx-auto my-8 border-[6px] border-congress-300 border-x-transparent rounded-full animate-spin" />
        <p className="font-protest text-center text-lg md:text-2xl lg:text-4xl my-6">Wir sind in Kürze für dich da...</p>
      </div>
    </div>
  )
}

export default Loader